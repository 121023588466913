import React from 'react';
export const messageTail = (
  <path d='M0.342304 14.5C7.35025 6.3293 3.35025 0.829295 0 0.0.0 0.0 5.4 2.1 32.3502 0.329295C32.3503 3.8293 -3.13481 20.7261 0.342304 14.5Z' />
);

export const magicWand = (
  <path
    d='M21.482 9.037H20.46a.75.75 0 100 1.5h1.022a.75.75 0 100-1.5zm-2.777-2.735c.197 0 .391-.08.53-.22l.724-.723a.753.753 0 00.22-.53.757.757 0 00-.219-.531c-.14-.14-.334-.22-.531-.22s-.391.081-.53.22l-.724.724a.75.75 0 00.53 1.28zM14.47 4.548a.75.75 0 00.75-.75V2.775a.75.75 0 10-1.5 0v1.023a.75.75 0 00.75.75zm4.724 8.903a.75.75 0 10-1.06 1.06l.764.764a.75.75 0 001.061-1.06zM9.705 6.083a.75.75 0 001.06-1.061l-.723-.723a.75.75 0 10-1.06 1.06zm4.909 2.131a1.793 1.793 0 00-2.475 0l-9.88 9.88a1.752 1.752 0 000 2.474l1.415 1.414c.325.326.778.513 1.237.513s.913-.187 1.237-.513l9.88-9.878c.325-.325.513-.778.513-1.238s-.188-.912-.513-1.237zM5.087 20.922a.214.214 0 01-.176.095.214.214 0 01-.177-.095L3.32 19.508a.25.25 0 010-.354l7.278-7.277 1.767 1.768zm9.88-9.879l-1.54 1.541-1.769-1.768L13.2 9.274a.25.25 0 01.354 0l1.414 1.416a.252.252 0 010 .353z'
    fill='white'
  />
);
